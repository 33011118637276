import { GET_DBOARD_ROAD_DATA_REQUEST_INIT, GET_DBOARD_ROAD_DATA_REQUEST_DONE, GET_DBOARD_ROAD_DATA_REQUEST_FAILURE } from "../constants";
import { Request, Server } from "utils";
import _ from 'lodash';

export const fnDashboardActions = {

    getDashboardRoadData() {
        return dispatch => {
            dispatch({ type: GET_DBOARD_ROAD_DATA_REQUEST_INIT });
            return Request.post('getDashboardRoadData', {}, Server.ROAD_API).then((res) => {                
                dispatch({type: GET_DBOARD_ROAD_DATA_REQUEST_DONE, payload: _.get(res, 'meta.data')});
                return Promise.resolve(_.get(res, 'meta.data'));
            }).catch(err => { 
                dispatch({type: GET_DBOARD_ROAD_DATA_REQUEST_FAILURE, payload: err});
                return Promise.reject(err);
            });
        }
    }
};